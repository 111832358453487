.vnrb-homeTopSlider {
  :global .slick-slide {
    visibility: hidden;
  }
  :global .slick-slide.slick-active {
    visibility: visible;
  }
  :global .slick-list {
    width: 100%;
    max-width: 892px;
    overflow: hidden;
    .slick-track {
      // width: 100% !important;
      display: inline-flex;
      @media screen and (min-width: 1140px) {
        height: 448px;
      }
    }
  }
  :global .slick-prev {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 2;
    top: calc(50% - 40px);
    left: 30px;
    position: absolute;
    border: solid 2px transparent;
    background: #FFF !important;
    cursor: pointer;
    display: grid !important;
    &::after {
      content: '\276E';
      color: #333;
      font-size: 20px;
      place-self: center;
    }
    &::before {
      display: none;
    }
    @media screen and (min-width: 1140px) {
      top: 220px;
    }
  }
  :global .slick-next {
    width: 48px;
    height: 48px;
    flex-grow: 0;
    border-radius: 50%;
    z-index: 2;
    top: calc(50% - 40px);
    right: 30px;
    position: absolute;
    border: solid 2px transparent;
    background: #FFF !important;
    color: transparent;
    cursor: pointer;
    display: grid !important;
    &::after {
      content: '\276F';
      color: #333;
      font-size: 20px;
      place-self: center;
    }
    &::before {
      display: none;
    }
    @media screen and (min-width: 1140px) {
      top: 220px;
    }
  }
  :global .slick-arrow {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }
  :global .slick-dots {
    width: 100%;
    align-items: center;
    bottom: 10px;
    justify-content: center;
    display: inline-flex !important;
    padding-top: 8px;
    gap: 8px;
  }
  :global li.slick-active {
    div {
      width: 32px;
      opacity: 1;
    }
  }
  // .dotsSlider {
  //   width: 12px;
  //   height: 4px;
  //   border-radius: 10px;
  //   text-decoration: none !important;
  //   background-color: #A7E05B;
  //   opacity: 30%;
  //   font-size: 0;
  // }

  img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    aspect-ratio: 892 / 448;
    @media screen and (min-width: 1140px) {
      // width: 892px !important;
      height: 448px !important;
    }
  }
}
.bannerHome {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 12px;
  aspect-ratio: 892 / 448;
  @media screen and (min-width: 1140px) {
    // width: 892px !important;
    height: 448px !important;
  }
}
.dotsSlider {
  width: 12px;
  height: 4px;
  border-radius: 10px;
  text-decoration: none !important;
  background-color: #A7E05B;
  opacity: 0.3;
  font-size: 0;
  @media screen and (max-width: 768px) {
    background-color: #333333;
  }
}
.imageBanner {
  width: 100%;
  height: auto;
}
