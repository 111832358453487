
.storyItem {
  margin-right: 8px;
  width: 206px !important;
  
   div {
    border-radius: 12px;
  }
}
.vnrYoutube {
  min-height: 370px;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
}
.time {
  padding: 4px 8px 4px 4px;
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);
  border-radius: 100px;
  span {
    color: #666666;
    font-size: 12px;
    line-height: 16px;
  }
}
.information {
  position: absolute;
  width: 100%;
  max-height: 152px;
  bottom: 0;
  left: 0;
  padding: 35px 12px 12px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.title {
  font-family: Nunito;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

//modal
.modalCreateResume {
  :global .slick-slider {
    width: 100%;
    // height: calc(100% - 48px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
  }
  :global .slick-list {
    width: 100%;
    // height: calc(100% - 48px);
    height: 100%;
    overflow: hidden;
    .slick-track {
      display: inline-flex;
    }
  }
  :global .slick-arrow {
    display: none !important;
  }
  :global .ant-modal {
    top: 5px !important;
    @media screen and (max-width: 576px) {
      top: 0 !important;
    }
  }
  :global .ant-modal-content {
    background-color: transparent;
  }
  :global .ant-modal-body {
    border-bottom: unset;
    padding: 0;
    text-align: center;
    position: relative;
  }
  :global .ant-modal-close {
    right: -80px;
    @media screen and (max-width: 576px) {
      right: 0;
      top: 8px;
    }
  }
  :global .ant-modal-close-x {
    svg {
      fill: #fff
    }
    width: 24px;
    height: 24px;
    line-height: 0;
    font-size: 24px;
    @media screen and (max-width: 576px) {
      width: 18px;
      height: 18px;
      line-height: 0;
      font-size: 18px;
    }
  }

  :global .slick-dots {
    // align-items: center;
    display: inline-flex !important;
    gap: 4px;
    position: absolute;
    width: 100%;
    top: 3px;
    left: 0;
    li {
      width: 100%;
      height: 3px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 10px;
      button {
        display: none;
      }
    }
  }
  :global li.slick-active  {
    background-color: #fff;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.nextButton {
  cursor: pointer;
  position: absolute;
  left: -95px;
  top: calc(50vh - 30px);
  align-items: center;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
  border-radius: 50%;
  background-color: #fff;
  border: solid 1px rgba(51, 51, 51, 0.2);
  svg {
    fill: rgb(30, 30, 30);
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.prevButton {
  cursor: pointer;
  position: absolute;
  right: -95px;
  top: calc(50vh - 30px);
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border: solid 1px rgba(51, 51, 51, 0.2);
  border-radius: 50%;
  svg {
    fill: rgb(30, 30, 30);
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.iframeClassName {
  width: 440px;
  max-width: 100%;
  height: 780px;
  max-height: calc(100vh - 16px);
  @media screen and (max-width: 576px) {
    height: calc(100vh - 16px) !important;
  }
}
.storyContentWrapper {
  position: relative;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.informationModal {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: start;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50%, #000000 100%)
}
.descriptionModal {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  display: block;
}