@font-face {
  font-family: Neue-Regular;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-65Medium.otf');
}

.postGroup {
  width: 100%;
  max-width: 1152px;
  margin: 0 auto 40px !important;
  padding-top: 55px;
  @media screen and (max-width: 768px) {
    padding: 40px 10px 10px;
    gap: 30px;
  }
}

.latestPostWrapper {
  border-radius: 12px;
  border: 2px solid transparent;
  transition: all ease 0.4s;
  .latestPost {
    transition: all ease 0.4s;
    padding: 5px;
  }
  &:hover {
    border-color: #fdcb12;
    .latestPost,
    .smallPostItem {
      transform: scale3d(0.96, 0.96, 0.96);
    }
  }
}

.cateNameWrapper {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cateName {
  font-family: Kanit;
  font-size: 18px;
  line-height: 1.5;
  color: #f4631d;
  font-weight: 500;
  display: inline-flex;
  gap: 5px;
}
.viewMore {
  color: #f4651d !important;
  font-family: Kanit;
}
.latestPostTitle {
  font-family: Neue;
  font-size: 18px;
  line-height: 28px;
  height: 84px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px;
  color: #2e2e2e !important;
  :global .magazine {
    font-size: 12px;
    line-height: 24px;
    background-color: #f9e5d5;
    border-radius: 4px;
    padding: 3px 8px;
    color: #FF7300;
    vertical-align: top;
    border: solid 1px #FF7300;
  }
}
.postThumbnail div {
  border-radius: 8px;
}
.polygonIcon {
}
//small
.smallPostItem {
  height: 90px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all ease 0.4s;
}
.smallPostItemThumbnail {
  flex: none;
  width: 130px;
  div {
    border-radius: 8px;
  }
}
.smallPostItemTitle {
  font-family: Neue;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  transition: all ease 0.4s;
  :global .magazine {
    font-size: 12px;
    line-height: 24px;
    background-color: #f9e5d5;
    border-radius: 4px;
    padding: 3px 8px;
    color: #FF7300;
    vertical-align: top;
    border: solid 1px #FF7300;
  }
}
.line {
  width: 100%;
  margin: 10px 0;
  height: 0.1px;
  background-color: #e8e8e8;
}
.viewMoreButton {
  color: #fff !important;
  background: linear-gradient(94.89deg, #ffca0c 0%, #f39d1c 100%);
  border: solid 2px #ffca0c;
  border-radius: 6px;
  padding: 7px 20px;
  font-size: 16px;
  line-height: 1.5;
  margin: 32px auto 0;
  width: fit-content;
  font-family: Kanit;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  transition: all ease 0.4s;
  &:hover {
    transform: scale(0.95);
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
}
.newPostExcerpt {
  color: #929292;
  /* Body/Small/Regular */
  font-family: Neue-Regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px; /* 133.333% */
  height: 64px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.postDetail {
  color: #2e2e2e;

  /* Body/Small/Regular */
  font-family: Neue-Regular;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  span {
    color: #0872ca !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
.rebateRate {
  width: 100%;
  display: flex;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid rgba(2, 114, 202, 0.2);
  background-color: #fff;
  box-shadow: 0px 4px 0px 0px rgba(8, 114, 202, 0.2);
  font-family: Neue;
  color: #0872ca;
  margin-top: 20px;
}
.groupTitle {
  color: #FF8104;
font-family: Neue;
font-size: 24px;
line-height: 32px; /* 133.333% */
}