.vnrb-homeTopSlider {
  :global .slick-list {
    width: 100%;
    overflow: hidden;
    .slick-track {
      display: inline-flex;
    }
  }
  :global .slick-prev {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 2;
    top: calc(50% - 40px);
    left: 2px;
    padding-top: 7px;
    position: absolute;
    border: solid 2px #fff;
    background: transparent;
    cursor: pointer;
    color: transparent;
    &::after {
      content: "\276E";
      color: #FFF;
      padding-top: 10px;
      font-size: 20px;
      margin-left: 17px;
    }
  }
  :global .slick-next {
    width: 48px;
    height: 48px;
    flex-grow: 0;
    border-radius: 50%;
    z-index: 2;
    top: calc(50% - 40px);
    right: 2px;
    position: absolute;
    border: solid 2px #fff;
    background: transparent;
    color: transparent;
    padding-top: 7px;
    cursor: pointer;
    &::after {
      content: "\276F";
      color: #FFF;
      padding-top: 10px;
      font-size: 20px;
      margin-left: 17px;
    }
  }
  
  :global .slick-arrow {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }
  :global .slick-dots {
    align-items: center;
    display: inline-flex !important;
    position: absolute;
    bottom: 0;
    right: calc(50% - 42px);
    @media screen and (max-width: 768px) {
      position: unset;
      width: 100%;
      justify-content: center;
    }
  }
  :global li.slick-active  {
    div {
      width: 12px;
      height: 12px;
      opacity: 1;
    }
  }
  @media screen and (min-width: 360px) {
    min-height: 245px;
  }
  @media screen and (min-width: 425px) {
    min-height: 270px;
  }
  @media screen and (min-width: 576px) {
    min-height: 345px;
  }
  .dotsSlider {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    text-decoration: none !important;
    background-color: #ff7300;
    margin: 0 14px 0 0;
    opacity: 0.6;
    font-size: 0;
    @media screen and (max-width: 768px) {
      background-color: #333333;
    }
  }
}
.vsaSlider {
  min-height: 125px;
  position: relative;
  :global .slick-list {
    width: 100%;
    overflow: hidden;
    // padding: 0px 35px !important;
    // @media screen and (max-width: 768px) {
    //   padding: 0px calc(370px - 50%) !important;
    // }
    .slick-track {
      display: inline-flex;
    }
  }
  :global .slick-disabled {
    opacity: 0.4 !important;
    cursor: not-allowed;
  }
  :global .slick-prev {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 2;
    top: -28px;
    left: unset;
    right: 40px;
    position: absolute;
    border: solid 1px rgba(51, 51, 51, 0.2);
    background: transparent;
    cursor: pointer;
    color: transparent;
    display: grid !important;
    &::after {
      content: "\276E";
      color: #333;
      font-size: 20px;
      place-self: center;
    }
    &::before {
      display: none;
    }
  }
  :global .slick-next {
    width: 32px;
    height: 32px;
    flex-grow: 0;
    border-radius: 50%;
    z-index: 2;
    top: -28px;
    right: 0;
    position: absolute;
    border: solid 1px rgba(51, 51, 51, 0.2);
    background: transparent;
    color: transparent;
    cursor: pointer;
    display: grid !important;
    &::after {
      content: "\276F";
      color: #333;
      font-size: 20px;
      place-self: center;
    }
    &::before {
      display: none;
    }
  }
  // :global .slick-arrow {
  //   @media screen and (max-width: 768px) {
  //     display: none !important;
  //   }
  // }
  :global .slick-dots {
    // align-items: center;
    // display: inline-flex !important;
    // position: absolute;
    // bottom: -40px;
    // right: calc(50% - 100px);
    // @media screen and (max-width: 768px) {
    //   position: unset;
    //   width: 100%;
    //   justify-content: center;
    // }
    display: none !important;
  }
  :global li.slick-active  {
    div {
      width: 12px;
      height: 12px;
      opacity: 1;
    }
  }
  :global .slick-track {
    // transform: translate3d(-50px, 0px, 0px) !important;
    // @media screen and (max-width: 768px) {
    //   transform: unset !important;
    // }
  }
  .dotsSlider {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    text-decoration: none !important;
    background-color: #ff7300;
    margin: 0 14px 0 0;
    opacity: 0.6;
    font-size: 0;
    @media screen and (max-width: 768px) {
      background-color: #333333;
    }
  }
}