.airdropCountdown {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 4px;
  @media screen and (max-width: 768px) {
    padding: 2px;
    gap: 5px;
    // height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    gap: 2px;
  }
}
.airdropCountdownDashboard {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 4px;
}
.title {
  font-size: 20px;
  font-weight: 900;
  color: #FF7300;
  line-height: 1;
  font-family: sans-serif;
  letter-spacing: -1px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.titleDashboard {
  font-size: 20px;
  font-weight: 900;
  color: #FF7300;
  line-height: 1;
  font-family: sans-serif;
  letter-spacing: -1px;
}
.endInDashboard {
  color: #000;
  span {
    padding-left: 3px;
  }
}
.endIn {
  color: #000;
  span {
    padding-left: 3px;
    @media screen and (max-width: 768px) {
      padding-left: 0;
      font-size: 10px;
      letter-spacing: -1px;
      display: none;
    }
  }
  span:first-child {
    @media screen and (max-width: 768px) {
     display: block;
    }
  }
  
}
.counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
   }
}
.counterDashboard {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.time {
  border-radius: 2px;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  display: flex;
  font-size: 18px;
  height: 18px;
  justify-content: space-around;
  line-height: 20px;
  min-width: 20px;
  padding: 1px 3px;
  text-align: center;
  background-color: #000;
  color: #FFF;
  @media screen and (max-width: 768px) {
    font-size: 10px !important;
    border-radius: 4px !important;
    min-width: 15px;
    height: 15px;
    line-height: 16px;
    padding: 0;
  }
}
.timeDashboard {
  border-radius: 2px;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  display: flex;
  font-size: 18px;
  height: 18px;
  justify-content: space-around;
  line-height: 20px;
  min-width: 20px;
  padding: 1px 3px;
  text-align: center;
  background-color: #000;
  color: #FFF;
}
.ended {
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}