@font-face {
  font-family: Neue;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-75Bold.otf');
}
@font-face {
  font-family: Neue-Regular;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-65Medium.otf');
}

.storyItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  width: 280px !important;
  height: 360px;
  border-radius: 12px;
border: 1px solid var(--Alpha-Primary-20, rgba(2, 114, 202, 0.20));
background: linear-gradient(180deg, #E4F3FF 0%, #FFF 100%);
box-shadow: 0px 4px 0px 0px rgba(8, 114, 202, 0.20);
padding: 12px;
   div {
    border-radius: 8px;
  }
}
.price {
  color: var(--Secondary-500, #FF8104);
font-family: Neue;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */

}
.orginalprice {
  color: var(--Neutral-400, #929292);
font-family: Neue-Regular;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
text-decoration: line-through;
}
.priceSection {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding-top: 12px;
}
.discountPrice {
  padding: 4px 8px 2px;
  background: var(--Status-Negative-500, #EE1926);
  border-radius: 0 !important;
  color: #FFF;

/* Body/Medium/Bold */
font-family: Neue;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 142.857% */
}
.vnrYoutube {
  min-height: 360px;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
}
.time {
  padding: 4px 8px 4px 4px;
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);
  border-radius: 100px;
  span {
    color: #666666;
    font-size: 12px;
    line-height: 16px;
  }
}
.information {
  padding-top: 12px;
}
.title {
  font-family: Neue;
  color: #2E2E2E;
  margin-bottom: 8px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // height: 60px;

/* Body/Medium/Bold */
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 142.857% */
}
.description {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nextButton {
  cursor: pointer;
  position: absolute;
  left: -95px;
  top: calc(50vh - 30px);
  align-items: center;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
  border-radius: 50%;
  background-color: #fff;
  border: solid 1px rgba(51, 51, 51, 0.2);
  svg {
    fill: rgb(30, 30, 30);
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.prevButton {
  cursor: pointer;
  position: absolute;
  right: -95px;
  top: calc(50vh - 30px);
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border: solid 1px rgba(51, 51, 51, 0.2);
  border-radius: 50%;
  svg {
    fill: rgb(30, 30, 30);
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.iframeClassName {
  width: 440px;
  max-width: 100%;
  height: 780px;
  max-height: calc(100vh - 16px);
  @media screen and (max-width: 576px) {
    height: calc(100vh - 16px) !important;
  }
}
.storyContentWrapper {
  position: relative;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.informationModal {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: start;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50%, #000000 100%)
}
.descriptionModal {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  display: block;
}
.buttonOpenAccount {
  width: 100%;
  display: flex;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-bottom: 2px solid #175b9e;
  box-shadow: 0px 4px 0px 0px #02569c;
  background-color: #0872ca;
  cursor: pointer;
  color: #FFF !important;
  font-family: Neue;
  font-size: 14px;
  line-height: 20px;
  margin-top: auto;
}
.wrapper {
  padding-top: 48px;
}
.vsaSlider {
  position: relative;
}
.blur {
  position: absolute;
  right: 12px;
  top: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
}
.titleSection {
  color: var(--Secondary-500, #FF8104);
font-family: Neue;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */
padding-bottom: 12px;
}